<template>
    <b-col sm="9">
        <div class="pub_filter_wrapper">
            <div class="row g-3">
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('publication_management.pub_type')"
                        label-for="pub_type"
                        >
                        <b-form-select
                        plain
                        v-model="search.pub_type_id"
                        :options="publicationTypeList"
                        id="pub_type"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('portal.title')"
                        label-for="pub_title"
                        >
                        <b-form-select
                        plain
                        v-model="search.pub_title_id"
                        :options="publicationTitleList"
                        id="pub_title"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="3" sm="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="year_en_id"
                        >
                        <template v-slot:label>
                        {{$t('globalTrans.year')}}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.year"
                        :options="yearListBn"
                        id="year_en_id"
                        >
                        </b-form-select>
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-group>
                </b-col>
                <b-col lg="3" sm="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="month_bn_id"
                        >
                        <template v-slot:label>
                        {{$t('globalTrans.month')}}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.month_id"
                        :options="banglaMonthList"
                        id="month_bn_id"
                        >
                        </b-form-select>
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('publication_management.content_area')"
                        label-for="content_area_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.content_area_id"
                        :options="contentAreaList"
                        id="content_area_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('publication_management.writer')"
                        label-for="writer"
                        >
                        <b-form-select
                        plain
                        v-model="search.user_id"
                        :options="userList"
                        id="writer"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <div class="col-sm-3 mt-4 pt-3">
                    <button type="button" @click="searchData" class="btn btn-primary w-100">{{ $t('globalTrans.search') }}</button>
                </div>
            </div>
        </div>
        <b-overlay :show="unitLoad">
            <div class="service_list_wrapper magazineList_wrapper">
              <div class="card card_wrapper">
                <div class="card-body p-0">
                  <div class="list-group">
                    <a v-for="(item, index) in publication" :key="index" href="javascript:" class="list-group-item list-group-item-action">
                      <div class="magazineList_item">
                        <div class="magazineList_cover">
                          <img src="../../../../../assets/images/portal/64277766.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="magazineList_description">
                          <h5 class="title">{{ item.content_title }}</h5>
                          <p class="writer"><i class="fas fa-user-edit"></i> {{ currentLocale == 'en' ? item.name : item.name_bn }}</p>
                          <p v-html="truncate(item.content, 280)" class="doc"><span>...</span></p>
                          <router-link :to="'/portal/publication/content-details?id='+item.id" class="btn_more">{{ $t('globalTrans.details') }} <i class="fas fa-arrow-right"></i></router-link>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <nav aria-label="Page navigation example">
                <b-pagination
                    class="justify-content-center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                />
            </nav>
            </div>
        </b-overlay>
    </b-col>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { contentListApi, userListApi } from '../../../api/routes'
import RouteBasedList from '@/mixins/route-base-list'
export default {
mixins: [RouteBasedList],
  components: {},
  props: [],
  data () {
    return {
        unitLoad: false,
        publication: [],
        search: {
            pub_type_id: 0,
            pub_title_id: 0,
            user_id: 0,
            month_id: 0,
            year: 0,
            content_area_id: 0
        },
        userList: [],
        publicationTitleList: []
    }
  },
  created () {
    this.loadData()
    this.loadUserList()
  },
  computed: {
    contentAreaList: function () {
        return this.$store.state.Portal.agriResearchObj.publicationContentAreaList.filter(type => type.status === 1).map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    publicationTypeList: function () {
        return this.$store.state.Portal.agriResearchObj.publicationTypeList.filter(itm => itm.status === 1).map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    },
    yearListBn: function () {
      var min = new Date().getFullYear()
      min = min - 596
      var max = min + 10
      var years = []
      for (var i = min; i <= max; i++) {
        if (this.$i18n.locale === 'bn') {
              years.push({ value: i, text: this.$n(i, { useGrouping: false }) })
          } else {
              years.push(i)
        }
      }
      return years
    },
    banglaMonthList: function () {
      const objectData = this.$store.state.commonObj.banglaMonthList
      return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
    watch: {
    'search.pub_type_id': function (newVal) {
      this.getPublicationTitleList()
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.pub_type_id) {
                this.getPublicationTitleList()
            }
        }
    }
  },
  methods: {
    truncate (str, n) {
      return (str.length > n) ? str.substr(0, n - 1) + '' : str
    },
    getPublicationTitleList () {
        this.publicationTitleList = this.$store.state.Portal.agriResearchObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === parseInt(this.search.pub_type_id)).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    async searchData () {
      this.loadData()
    },
    loadUserList () {
        // this.search.pub_title_id = this.search.pub_title_id ? this.search.pub_title_id : this.$route.query.title
        const params = {}
        RestApi.getData(agriResearchServiceBaseUrl, userListApi, params).then(response => {
            if (response.success) {
                this.userList = response.data.map(item => {
                    return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
                })
            } else {
                this.userList = []
            }
        })
    },
    loadData () {
        this.unitLoad = true
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        RestApi.getData(agriResearchServiceBaseUrl, contentListApi, params).then(response => {
            if (response.success) {
                this.publication = this.getRelationalData(response.data.data)
                this.$store.dispatch('setList', this.publication)
                this.paginationData(response.data)
                this.unitLoad = false
            } else {
                this.publication = []
                this.unitLoad = false
            }
        })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpTitle = this.$store.state.Portal.agriResearchObj.publicationTitleList.find(newItem => newItem.value === item.pub_title_id)
          const tmpBanglaMonth = this.$store.state.commonObj.banglaMonthList.find(newItem => newItem.value === parseInt(item.month_bn))
          const newData = {
              pub_title_name: tmpTitle !== undefined ? tmpTitle.text_en : '',
              pub_title_name_bn: tmpTitle !== undefined ? tmpTitle.text_bn : '',
              bangla_month_name: tmpBanglaMonth !== undefined ? tmpBanglaMonth.text_en : '',
              bangla_month_name_bn: tmpBanglaMonth !== undefined ? tmpBanglaMonth.text_bn : '',
              portal_type: this.$route.query.type
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>

<style>

</style>
