<template>
  <div class="services_section publication_wrapper">
    <TopBanner :title="$route.query.magazine_list ? $t('portal.magazine_list') : getPubTitleName()" />
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ getPubTitleName() }}</li>
                <li v-if="$route.query.magazine_list" class="breadcrumb-item active" aria-current="page">{{ $t('portal.magazine_list') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_container_wrapper magazineDetails_wrapper">
      <b-container>
        <b-row>
          <b-col sm="3">
            <Sidebar :isActive="isActive" />
            <Subscription v-if="$route.query.type == 1 || $route.query.type == 5 || $route.query.type == 'content'" />
            <Article v-if="$route.query.type == 1 || $route.query.type == 5 || $route.query.type == 'content'" />
          </b-col>
          <component :is='comp' />
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>

import TopBanner from '../../components/publication/TopBanner.vue'
import Sidebar from '../../components/publication/Sidebar.vue'
import Magazine from '../../components/publication/magazine/magazine.vue'
import Content from '../../components/publication/content/Content.vue'
import Banner from '../../components/publication/banner/Banner.vue'
import Poster from '../../components/publication/poster/Poster.vue'
import Leaflet from '../../components/publication/leaflet/Leaflet.vue'
import Others from '../../components/publication/others/Others.vue'
import ResearchReport from '../../components/publication/research-report/ResearchReport.vue'
import RouteBasedList from '@/mixins/route-base-list'
import Article from '../../components/publication/Article.vue'
import Subscription from '../../components/publication/Subscription.vue'
export default {
  mixins: [RouteBasedList],
  components: {
    TopBanner,
    Sidebar,
    Magazine,
    Content,
    Banner,
    Poster,
    Leaflet,
    ResearchReport,
    Others,
    Article,
    Subscription
  },
  data () {
    return {
        comp: '',
        isActive: 0,
        unitLoad: false,
        pub_type_id: 0
    }
  },
  watch: {
    '$route.query.type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
          if (parseInt(newVal) === 1) {
              this.isActive = parseInt(newVal)
              this.comp = 'Magazine'
          } else if (parseInt(newVal) === 2) {
              this.isActive = parseInt(newVal)
              this.comp = 'Banner'
          } else if (parseInt(newVal) === 3) {
              this.isActive = parseInt(newVal)
              this.comp = 'Poster'
          } else if (parseInt(newVal) === 4) {
              this.isActive = parseInt(newVal)
              this.comp = 'Leaflet'
          } else if (parseInt(newVal) === 5) {
              this.isActive = parseInt(newVal)
              this.comp = 'ResearchReport'
          } else if (newVal === 'content') {
              this.isActive = 'content'
              this.comp = 'Content'
          } else {
              this.isActive = parseInt(newVal)
              this.comp = 'Others'
          }
      }
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getPubTitleName()
        }
    }
  },
  created () {
    if (this.$route.query.type) {
        this.pub_type_id = this.$route.query.id
        if (parseInt(this.$route.query.type) === 1) {
            this.isActive = parseInt(this.$route.query.type)
            this.comp = 'Magazine'
        } else if (parseInt(this.$route.query.type) === 2) {
            this.isActive = parseInt(this.$route.query.type)
            this.comp = 'Banner'
        } else if (parseInt(this.$route.query.type) === 3) {
            this.isActive = parseInt(this.$route.query.type)
            this.comp = 'Poster'
        } else if (parseInt(this.$route.query.type) === 4) {
            this.isActive = parseInt(this.$route.query.type)
            this.comp = 'Leaflet'
        } else if (parseInt(this.$route.query.type) === 5) {
            this.isActive = parseInt(this.$route.query.type)
            this.comp = 'ResearchReport'
        } else if (this.$route.query.type === 'content') {
            this.isActive = 'content'
            this.comp = 'Content'
        } else {
            this.isActive = parseInt(this.$route.query.type)
            this.comp = 'Others'
        }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getPubTitleName () {
      if (parseInt(this.$route.query.content) === 1) {
        return this.$t('portal.content')
      } if (this.$route.query.home) {
        return this.$t('portal.pub_management')
      } else {
        const Id = this.$route.query.magazine_list ? this.$route.query.magazine_list : this.$route.query.id
        const Obj = this.$store.state.Portal.agriResearchObj.publicationTypeList.find(newItem => newItem.value === parseInt(Id))
        if (this.$i18n.locale === 'bn') {
          return Obj.text_bn
        } else {
          return Obj.text_en
        }
      }
    }
  }
}
</script>
