<template>
    <div class="subscription_wrapper card">
        <a href="javascrip:" @click="setServiceUrl()">
            <div class="card-header">
                <h4 class="title text-white tada"><i class="far fa-hand-point-up"></i> {{ $t('portal.subscription') }}</h4>
            </div>
            <div class="card-body">
                <p class="mb-0">{{ $t('portal.subscribe_for_magazine') }}</p>
            </div>
        </a>
    </div>
</template>

<script>
export default {
  components: {},
  props: ['title'],
  data () {
    return {}
  },
  methods: {
    setServiceUrl () {
      const url = '/research-farmer/publication-management/publication-subscription-form'
      this.$store.dispatch('Portal/setServiceUrl', {
        externalPanelServiceUrl: url,
        externalPanelCurrentServiceUrl: url
      })
      this.$router.push('/auth/login')
    }
  }
}
</script>

<style>

</style>
