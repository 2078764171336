<template>
  <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ title }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
</template>

<script>
export default {
  components: {},
  props: ['title'],
  data () {
    return {}
  }
}
</script>

<style>

</style>
