<template>
    <b-col sm="9">
        <div class="pub_filter_wrapper">
            <div class="row g-3">
                <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('portal.title')"
                        label-for="pub_title"
                        >
                        <b-form-select
                        plain
                        v-model="search.pub_title_id"
                        :options="publicationTitleList"
                        id="pub_title"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('globalTrans.organization')"
                        label-for="org_list"
                        >
                        <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="organizationProfileList"
                        id="org_list"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <div class="col mb-3">
                    <div class="btn_filter_wrapper">
                    <button type="button" @click="searchData" class="btn btn-primary w-100">{{ $t('globalTrans.search') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <b-overlay :show="unitLoad">
            <div class="service_list_wrapper">
                <div class="card card_wrapper">
                    <div class="card-header">
                        <h5 class="title"><i class="fas fa-layer-group"></i> {{ $t('portal.all_leaflet') }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div v-for="(item, index) in publication" :key="index" class="col-sm-3">
                                <a href="javascript:" @click="getData(item)" title="Krishi Kotha">
                                    <div class="card_item">
                                        <div class="img_wrapper magazine_wrapper">
                                            <div class="bookWrap">
                                                <div class="book">
                                                    <img class="cover" :src="baseUrl + 'storage/publication-upload/' + item.cover_photo">
                                                </div>
                                            </div>
                                        </div>
                                        <p class="title">{{ currentLocale == 'bn' ? item.pub_title_name_bn : item.pub_title_name }}</p>
                                        <small class="date">{{ $t('publication_management.publish_date') }}: {{ item.updated_at | dateFormat }}</small>
                                        <small class="date">{{ currentLocale == 'bn' ? item.org_name_bn : item.org_name }}</small>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example">
                    <b-pagination
                        class="justify-content-center"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                    />
                </nav>
            </div>
        </b-overlay>
        <b-modal id="banner-modal" centered hide-footer>
            <template #modal-title>
                {{ currentLocale == 'bn' ? tmpData.pub_title_name_bn : tmpData.pub_title_name }}
            </template>
            <div class="model_body_wrapper">
                <p class="mb-1"><span class="sub_title">{{ $t('publication_management.publish_date') }}:</span> {{ tmpData.updated_at | dateFormat }}</p>
                <p><span class="sub_title">{{ $t('globalTrans.organization') }}:</span> {{ currentLocale == 'bn' ? tmpData.org_name_bn : tmpData.org_name }}</p>
                <div class="d-block text-center mb-2">
                    <img :src="baseUrl + 'storage/publication-upload/' + tmpData.cover_photo" class="img-fluid" alt="">
                </div>
                <p><span class="sub_title">{{ $t('globalTrans.description') }}:</span> {{ currentLocale == 'bn' ? tmpData.description_bn : tmpData.description }}</p>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-3">
                <a class="mr-3 btn btn-primary" target="_blank" :href="baseUrl + 'download-attachment?file=app/public/publication-upload/' + tmpData.cover_photo" variant="primary"><i class="far fa-file-pdf"></i> {{ $t('portal.download') }}</a>
                <b-button variant="outline-danger" @click="$bvModal.hide('banner-modal')">{{ $t('globalTrans.close') }}</b-button>
            </div>
        </b-modal>
    </b-col>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { magazineListApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
export default {
mixins: [ModalBaseMasterList],
  components: {
  },
  props: [],
  data () {
    return {
        unitLoad: false,
        publication: [],
        search: {
            pub_type_id: 0,
            pub_title_id: 0,
            org_id: 0
        },
        baseUrl: agriResearchServiceBaseUrl,
        tmpData: {}
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    organizationProfileList: function () {
        return this.$store.state.Portal.agriResearchObj.organizationProfileList
    },
    publicationTitleList: function () {
        return this.$store.state.Portal.agriResearchObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === parseInt(this.$route.query.id)).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getData (newitem) {
        const tmpData = this.$store.state.list.find(item => item.id === newitem.id)
        this.tmpData = JSON.parse(JSON.stringify(tmpData))
        if (tmpData) {
            this.$bvModal.show('banner-modal')
        }
    },
    async searchData () {
      this.loadData()
    },
    loadData () {
      this.unitLoad = true
        this.search.pub_type_id = this.$route.query.id
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(agriResearchServiceBaseUrl, magazineListApi, params).then(response => {
          if (response.success) {
            this.publication = this.getRelationalData(response.data.data)
            this.$store.dispatch('setList', this.publication)
            this.paginationData(response.data)
          }
          this.unitLoad = false
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpTitle = this.$store.state.Portal.agriResearchObj.publicationTitleList.find(newItem => newItem.value === item.pub_title_id)
          const tmpOrg = this.$store.state.Portal.agriResearchObj.organizationProfileList.find(newItem => newItem.value === item.org_id)
          const newData = {
              pub_title_name: tmpTitle !== undefined ? tmpTitle.text_en : '',
              pub_title_name_bn: tmpTitle !== undefined ? tmpTitle.text_bn : '',
              org_name: tmpOrg !== undefined ? tmpOrg.text_en : '',
              org_name_bn: tmpOrg !== undefined ? tmpOrg.text_bn : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>

<style>

</style>
