<template>
    <b-col sm="9">
        <div class="pub_filter_wrapper">
            <div class="row g-3">
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('portal.title')"
                        label-for="pub_title"
                        >
                        <b-form-select
                        plain
                        v-model="search.pub_title_id"
                        :options="publicationTitleList"
                        id="pub_title"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="3" sm="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="year_en_id"
                        >
                        <template v-slot:label>
                        {{$t('globalTrans.year')}}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.year"
                        :options="yearListBn"
                        id="year_en_id"
                        >
                        </b-form-select>
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-group>
                </b-col>
                <b-col lg="3" sm="3">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="month_bn_id"
                        >
                        <template v-slot:label>
                        {{$t('globalTrans.month')}}
                        </template>
                        <b-form-select
                        plain
                        v-model="search.month_id"
                        :options="banglaMonthList"
                        id="month_bn_id"
                        >
                        </b-form-select>
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-group>
                </b-col>
                <div class="col mb-3">
                    <div class="btn_filter_wrapper">
                        <button type="button" @click="searchData" class="btn btn-primary w-100">{{ $t('globalTrans.search') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <b-overlay :show="unitLoad">
            <div class="service_list_wrapper">
                <div class="card card_wrapper">
                    <div class="card-header">
                    <h5 class="title"><i class="fas fa-history"></i> {{ $t('portal.recent_magazine') }}</h5>
                    </div>
                    <div class="card-body">
                    <div class="row">
                        <div v-for="(item, index) in publication.slice(0, 3)" :key="index" class="col-sm-3">
                            <router-link :to="'/portal/publication/magazine-details?id='+item.id" :title="currentLocale == 'bn' ? item.pub_title_name_bn : item.pub_title_name">
                                <div class="card_item">
                                    <div class="img_wrapper magazine_wrapper">
                                        <div class="bookWrap">
                                            <div class="book">
                                                <img class="cover" :src="baseUrl + 'storage/publication-upload/' + item.cover_photo" :alt="item.cover_photo">
                                            </div>
                                        </div>
                                    </div>
                                    <p class="title">{{ currentLocale == 'bn' ? item.pub_title_name_bn : item.pub_title_name }}</p>
                                    <small class="date">{{ (currentLocale == 'bn' ? item.bangla_month_name_bn : item.bangla_month_name) + ' ' + $n(item.year_bn, { useGrouping: false }) }}</small>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="card card_wrapper">
                    <div class="card-header">
                    <h5 class="title"><i class="fas fa-layer-group"></i> {{ $t('portal.all_magazine') }}</h5>
                    <!-- <div>
                        <a href="javascript:"><span class="badge badge-light">আরো দেখুন</span></a>
                    </div> -->
                    </div>
                    <div class="card-body">
                    <div class="row">
                        <div v-for="(item, index) in publication" :key="index" class="col-sm-3">
                            <router-link :to="'/portal/publication/magazine-details?id='+item.id" :title="currentLocale == 'bn' ? item.pub_title_name_bn : item.pub_title_name">
                                <div class="card_item">
                                    <div class="img_wrapper magazine_wrapper">
                                        <div class="bookWrap">
                                            <div class="book">
                                                <img class="cover" :src="baseUrl + 'storage/publication-upload/' + item.cover_photo" :alt="item.cover_photo">
                                            </div>
                                        </div>
                                    </div>
                                    <p class="title">{{ currentLocale == 'bn' ? item.pub_title_name_bn : item.pub_title_name }}</p>
                                    <small class="date">{{ (currentLocale == 'bn' ? item.bangla_month_name_bn : item.bangla_month_name) + ' ' + $n(item.year_bn, { useGrouping: false }) }}</small>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    </div>
                </div>
                <nav aria-label="Page navigation example">
                    <b-pagination
                        class="justify-content-center"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                    />
                </nav>
            </div>
        </b-overlay>
    </b-col>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { magazineListApi } from '../../../api/routes'
import RouteBasedList from '@/mixins/route-base-list'
export default {
mixins: [RouteBasedList],
  components: {},
  props: [],
  data () {
    return {
        unitLoad: false,
        publication: [],
        search: {
            pub_type_id: 0,
            pub_title_id: 0,
            month_id: 0,
            year: 0
        },
        baseUrl: agriResearchServiceBaseUrl
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    publicationTitleList: function () {
        return this.$store.state.Portal.agriResearchObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === parseInt(this.$route.query.id)).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    yearListBn: function () {
      var min = new Date().getFullYear()
      min = min - 596
      var max = min + 10
      var years = []
      for (var i = min; i <= max; i++) {
        if (this.$i18n.locale === 'bn') {
              years.push({ value: i, text: this.$n(i, { useGrouping: false }) })
          } else {
              years.push(i)
        }
      }
      return years
    },
    banglaMonthList: function () {
      const objectData = this.$store.state.commonObj.banglaMonthList
      return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async searchData () {
      this.loadData()
    },
    loadData () {
        this.unitLoad = true
        this.search.pub_type_id = this.$route.query.id
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        RestApi.getData(agriResearchServiceBaseUrl, magazineListApi, params).then(response => {
            if (response.success) {
                this.publication = this.getRelationalData(response.data.data)
                this.$store.dispatch('setList', this.publication)
                this.paginationData(response.data)
            }
            this.unitLoad = false
        })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpTitle = this.$store.state.Portal.agriResearchObj.publicationTitleList.find(newItem => newItem.value === item.pub_title_id)
          const tmpBanglaMonth = this.$store.state.commonObj.banglaMonthList.find(newItem => newItem.value === parseInt(item.month_bn))
          const newData = {
              pub_title_name: tmpTitle !== undefined ? tmpTitle.text_en : '',
              pub_title_name_bn: tmpTitle !== undefined ? tmpTitle.text_bn : '',
              bangla_month_name: tmpBanglaMonth !== undefined ? tmpBanglaMonth.text_en : '',
              bangla_month_name_bn: tmpBanglaMonth !== undefined ? tmpBanglaMonth.text_bn : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>

<style>

</style>
