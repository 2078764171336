<template>
    <b-overlay :show="unitLoad">
        <div class="sidebar_wrapper">
            <div class="list-group">
                <span v-for="(item, index) in menuList" :key="index">
                    <router-link v-if="item.publications.length > 0" :to="'/portal/publication?id='+item.pub_type_id+'&&type='+item.type" target="_self" :class="(isActive === item.type ? 'active ' : '') + 'list-group-item list-group-item-action'">
                        {{ currentLocale === 'en' ? item.pub_type_name : item.pub_type_name_bn }} <span class="badge badge-pill service_ind_counter">{{ $n(item.publications.length, { useGrouping: false }) }}</span>
                    </router-link>
                </span>
                <router-link v-if="totalContent > 0" :to="'/portal/publication?content='+1+'&&type=content'" target="_self" :class="(isActive === 'content' ? 'active ' : '') + 'list-group-item list-group-item-action'">
                    {{ $t('portal.content') }} <span class="badge badge-pill service_ind_counter">{{ $n(totalContent, { useGrouping: false }) }}</span>
                </router-link>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { menuListApi } from '../../api/routes'
export default {
    components: {},
    props: ['isActive'],
    data () {
        return {
            unitLoad: false,
            menuList: [],
            totalContent: 0
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        async searchData () {
            this.loadData()
        },
        loadData () {
            this.unitLoad = true
            const params = {}
            RestApi.getData(agriResearchServiceBaseUrl, menuListApi, params).then(response => {
                if (response.success) {
                    this.menuList = this.getRelationalData(response.data)
                    this.totalContent = response.totalContent ? response.totalContent : 0
                }
                this.unitLoad = false
            })
        },
        getRelationalData (data) {
            const listData = data.map(item => {
                const tmpType = this.$store.state.Portal.agriResearchObj.publicationTypeList.find(newItem => newItem.value === item.pub_type_id && newItem.status === 1)
                const newData = {
                    pub_type_name: tmpType !== undefined ? tmpType.text_en : '',
                    pub_type_name_bn: tmpType !== undefined ? tmpType.text_bn : '',
                    type: tmpType.portal_type
                }
                return Object.assign({}, item, newData)
            })
            return listData
        }
    }
}
</script>

<style>

</style>
