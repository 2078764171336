<template>
    <div class="sidebar_wrapper">
        <div class="card">
            <div class="card-header">
                <h5 class="title"><i class="fas fa-align-center"></i> {{ $t('publication_management.content_area') }}</h5>
            </div>
            <ul class="list-group list-group-flush">
                <li v-for="(item, index) in contentAreaList" :key="index" class="list-group-item">
                    <a href="javascript:">{{ item.text }}</a>
                </li>
                <!-- <li class="list-group-item"><a href="javascript:">কৃষি প্রযুক্তি</a></li>
                <li class="list-group-item"><a href="javascript:">কৃষিতে নারী</a></li>
                <li class="list-group-item"><a href="javascript:">চাষ পদ্ধতি</a></li>
                <li class="list-group-item"><a href="javascript:">কৃষি উদ্যোক্তা</a></li> -->
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  components: {},
  props: ['title'],
  data () {
    return {}
  },
  computed: {
    contentAreaList: function () {
        return this.$store.state.Portal.agriResearchObj.publicationContentAreaList.filter(type => type.status === 1).map(item => {
            return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
    }
  }
}
</script>

<style>

</style>
